<template>
    <v-row>
        <template
            v-for="(link, i) in mainMenuComputed"
        >
            <v-col
                :key="'home-main-menu-item' + i"
                cols="12"
                :sm="smComputed"
            >
                <v-card
                    class="pa-4"
                    :to="link.to"
                >
                    <div class="text-center mb-3">
                        <w-icon
                            :value="link.icon"
                            color="primary"
                            size="26"
                        />
                    </div>
                    <div class="text-center">
                        {{ $trans(link.title) }}
                    </div>
                </v-card>
            </v-col>
        </template>
    </v-row>
</template>

<script>
import mainMenuInitMixin from '@/layouts/mixins/mainMenuInitMixin'

export default {
    name: 'HomeMainMenu',
    mixins: [
        mainMenuInitMixin
    ],
    computed: {
        mainMenuComputed() {
            const items = []

            this.mainMenu.forEach(o => {
                if(o.title !== 'Home') {
                    items.push(o)
                }
            })

            return items
        },
        smComputed() {
            const itemsLength = this.mainMenuComputed.length

            if(itemsLength === 1) {
                return 12
            }

            if(itemsLength % 3 === 0) {
                return 4
            }

            if(itemsLength % 2 === 0) {
                return 6
            }

            return 4
        }
    }
}
</script>

<style lang=scss>

</style>
