import { AuthProvider } from '@apps/auth/utils'

const auth = new AuthProvider()

const widgets = []

if(auth.isStudent()) {
    widgets.push({
        name: 'ScheduleMember',
        component: () => import('@/apps/school/components/Schedule/ScheduleMember')
    })
}

export default widgets
