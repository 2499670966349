// Register apps home widgets
const appsHomeWidgets = require.context('@/apps', true, /home.js$/)

export default appsHomeWidgets.keys()
    .reduce((widgets, path) => {
        const value = appsHomeWidgets(path)
        const collection = value.default

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < collection.length; i++) {
            widgets.push(collection[i])
        }

        return widgets
    }, [])
