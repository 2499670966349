<template>
    <content-wrapper
        :title="title"
    >
        <home-main-menu/>
        <v-row>
            <v-col
                v-for="(widget, i) in homeWidgets"
                :key="`home-widget-${i}`"
            >
                <component
                    :is="widget.name"
                />
            </v-col>
        </v-row>
    </content-wrapper>
</template>

<script>
/* eslint-disable */
import ContentWrapper from '@/components/ContentWrapper'
import HomeMainMenu from '@/components/Home/HomeMainMenu'
import appsHomeWidgets from '@/plugins/appsHomeWidgets'

const widgetsComponents = {}
appsHomeWidgets.forEach(o => {
    widgetsComponents[o.name] = o.component
})

export default {
    components: {
        HomeMainMenu,
        ContentWrapper,
        ...widgetsComponents
    },
    data() {
        return {}
    },
    computed: {
        title() {
            if(!this.$auth.member) {
                return ''
            }

            const hello = this.$trans('Hello')
            const firstName = this.$auth.member.first_name
            const middleName = this.$auth.member.middle_name
            const lastName = this.$auth.member.last_name

            return `${hello}, ${firstName} ${middleName || lastName}`
        },
        homeWidgets() {
            return appsHomeWidgets
        }
    }
}
</script>
